
import Vue from "vue";
import { ListAllChats, ListChatMsgs, CreateMsg } from "../../repositories/chat";
import { inbox, msg, msgRequest } from "../../models/chat";
import { mapGetters } from "vuex";
import router from "../../router";
import { addParamsToLocation, image } from "../../utils/helpers";
export default Vue.extend({
  name: "users-list",
  components: {},
  data() {
    return {
      loading: false,
      msg: "",
      panels: 0,
      chatLoading: false,
      chatsLoading: false,
      usersLoading: false,
      chats: [] as inbox[],
      users: [] as inbox[],
      chatsResp: {} as { chats: inbox[]; users: inbox[] },
      currentChat: null as inbox | null,
      msgs: [] as msg[],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
    }),
  },
  methods: {
    search($event) {
      this["chats"] = this.chatsResp["chats"].filter((item: inbox) =>
        item.Name.includes($event)
      );
      this["users"] = this.chatsResp["users"].filter((item: inbox) =>
        item.Name.includes($event)
      );
    },
    image,
    edit() {
      const currentPath = router.currentRoute.path;
      const path = currentPath.replace("view", "edit");
      router.push(path);
    },
    async listChats() {
      this.chatsLoading = true;
      await ListAllChats().then((res) => {
        this.chatsResp = res;
        this.chats = res.chats;
        this.users = res.users;
        if (this.$route.query.id){
          const merged = res.chats.concat(res.users)
          const id = parseInt(this.$route.query.id as string);
          const chat = merged.filter((chat : inbox) => chat.Id === id)[0]
          console.log(id)
          console.log(chat)
          this.selectChat(chat)
        }
        this.chatsLoading = false;
      });
    },

    selectChat(chat: inbox) {
      this.currentChat = chat;
      addParamsToLocation({ id: chat.Id }, router.currentRoute.path);
      this.findChatMsgs(chat.Id);
    },
    findChatMsgs(id: number) {
      this.chatLoading = true;
      ListChatMsgs(id).then((res: msg[]) => {
        this.msgs = res;
        this.chatLoading = false;
        // const elem = document.querySelector(".chat-msgs")!;
        // elem.scrollTop = elem.scrollHeight;
      });
    },
    send() {
      const payload: msgRequest = {
        ToId: this.currentChat!.Id,
        Msg: this.msg,
      };
      CreateMsg(payload).then((res: any) => {
        const msg: msg = {
          Id: res,
          Msg: this.msg,
          Mine: true,
          Name: "",
          CreatedAt: new Date().toString(),
          Seen: false,
        };
        this.msgs.push(msg);
        this.msg = "";
      });
    },
  },
  async mounted() {
    await this.listChats();
   
  },
});
